
export class AuthService {
    static getAuthUrl() {


        DEBUG && console.info('==> getAuthUrl()');

        fetch(`${apiRoot}/api/Auth/GetAuthUrl?webRoot=${webRoot}`, {
            headers: {
                "Content-Type": "text/plain"
            }
        })
            .then(response => response.text())
            .then(obj => {
                DEBUG && console.info(obj);

                if (obj) {
                    sessionStorage.setItem("authUrl", obj);
                }                    

                return obj;
            })
            .catch(error => console.error(error));
    }

    static LogOut() {


        DEBUG && console.info('==> LogOut()');

        fetch(`${apiRoot}/api/Auth/GetLogOutUrl?webRoot=${webRoot}`, {
            headers: {
                "Content-Type": "text/plain"
            }
        })
            .then(response => response.text())
            .then(obj => {
                DEBUG && console.info(obj);

                obj = obj.replace("{token}", localStorage.getItem('mpp-widgets_IdToken'));

                this.clearAuthTokens();

                var logoutUrl = `${obj}&state=${encodeURIComponent(window.location.href)}`;

                window.location.href = logoutUrl;
            })
            .catch(error => console.error(error));
    }

    static getTokenFromCode(code, webRoot, redirectUrl) {
        DEBUG && console.info('==> getTokenFromCode()');
        const payload = {
            code: code,
            webRoot: webRoot
        };

        // Configure the request
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload) // Convert data to JSON format
        };

        // Sending the POST request
        fetch(`${apiRoot}/api/Auth/GetTokenFromCode`, requestOptions)
            .then(response => response.json())
            .then(obj => {
                DEBUG && console.info(obj);

                this.setAuthTokens(obj);

                window.location.href = redirectUrl;
            })
            .catch(error => console.error(error));
    }

    static getTokenFromRefesh(refreshToken, webRoot, redirectUrl) {
        DEBUG && console.info('==> getTokenFromRefresh()');
        const payload = {
            refreshToken: code,
            webRoot: webRoot
        };

        // Configure the request
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload) // Convert data to JSON format
        };

        // Sending the POST request
        fetch(`${apiRoot}/api/Auth/GetTokenFromRefresh`, requestOptions)
            .then(response => response.json())
            .then(obj => {
                DEBUG && console.info(obj);

                this.setAuthTokens(obj);

                window.location.href = redirectUrl;
            })
            .catch(error => {
                console.error(error);
                this.redirectToAuthentication();                
            });
    }

    static setAuthTokens(tokens) {
        localStorage.setItem('mpp-widgets_AuthToken', tokens.access_token);
        localStorage.setItem('mpp-widgets_IdToken', tokens.id_token);

        // Create a new Date object
        const myDate = new Date(); // This will create a Date object representing the current date and time.

        // Adding expiration seconds to the Date object
        myDate.setSeconds(myDate.getSeconds() + tokens.expires_in);

        localStorage.setItem('mpp-widgets_ExpiresAfter', myDate);

        localStorage.setItem('mpp-widgets_Refresh', tokens.refresh_token);
    }

    static clearAuthTokens() {
        localStorage.removeItem('mpp-widgets_AuthToken');
        localStorage.removeItem('mpp-widgets_IdToken');
        localStorage.removeItem('mpp-widgets_ExpiresAfter');
        localStorage.removeItem('mpp-widgets_Refresh');
        sessionStorage.removeItem('userObj');
    }

    static checkAuthenticated() {
        DEBUG && console.info('==> checkAuthenticated()');
        var authToken = localStorage.getItem("mpp-widgets_AuthToken");
        var authExpiration = localStorage.getItem("mpp-widgets_ExpiresAfter");

        if (!authToken || !authExpiration) {
            this.clearAuthTokens();
            this.redirectToAuthentication();
        }

        // Check Authentication Expiration
        var expireDT = Date.parse(authExpiration);
        var now = new Date();

        if (now > expireDT) {
            console.info('Attempting to get new Token via Refresh');
        }


    }

    static getAuthentication() {
        return new Promise(function (resolve, reject) {
            var authToken = localStorage.getItem("mpp-widgets_AuthToken");
            var authExpiration = localStorage.getItem("mpp-widgets_ExpiresAfter");


            if (authToken === null || authToken === undefined || authToken == "null") {
                return resolve(null);
            }

            if (authExpiration === null || authExpiration === undefined || authExpiration == "null") {
                return resolve(null);
            }

            var authObj = {};
            authObj.authToken = authToken;
            authObj.authExpiration = authExpiration;

            // Retrieve session userObect
            var userObj = sessionStorage.getItem('userObj');

            if (userObj) {
                try {
                    // Attempt to parse Session UserObject
                    var userJsonObj = JSON.parse(userObj);

                    console.log("User Auth Object")
                    console.log(userJsonObj);
                    console.log("END User Auth Object")

                    // Check for valid expiration

                    return resolve(userJsonObj);
                }
                catch { }
            }

            // Configure the Request
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`
                }
            };

            // Sending the POST request
            fetch(`${apiRoot}/api/User/GetBasicUser`, requestOptions)
                .then(response => response.json())
                .then(obj => {
                    DEBUG && console.info(obj);

                    // Set User Session Object
                    sessionStorage.setItem('userObj', JSON.stringify(obj));

                    return resolve(obj);
                })
                .catch(error => {
                    return reject(error);
                });
        });
    }

    static redirectToAuthentication() {
        var authUrl = sessionStorage.getItem("authUrl");
        window.location.href = `${authUrl}&state=${window.location.href}`;
    }

    static initAuthButtons() {

        var elements = document.querySelectorAll('[data-component="loginLink"]');

        elements.forEach(element => {
            DEBUG && console.info('**************************************************');
            DEBUG && console.info(`Element ID:              ${element.id}`);
            DEBUG && console.info(`==> Added Auth Callback `)
            DEBUG && console.info('**************************************************');

            // Check if User if Logged In
            this.getAuthentication().then(authObj => {
                if (authObj === null) {
                    // Add an onclick event handler to element
                    element.addEventListener("click", function () {
                        var authUrl = sessionStorage.getItem("authUrl");
                        window.location.href = `${authUrl}&state=${window.location.href}`;
                    });
                }
                else {
                    this.showUserMenu(authObj, element);
                }   
            });         
        });
    }

    static showUserMenu(authObj, element) {

        let self = this;
        
        DEBUG && console.info('**************************************************');
        DEBUG && console.info(`==> Authenticated User `);
        DEBUG && console.info(`==> Setup User Menu `);
        DEBUG && console.info('**************************************************');

        var userMenuTemplate = `

        <div class="dropdown" style="margin-top:-5px;">
          <a href="#" class="avatar avatar-lg rounded-circle dropdown-toggle" data-bs-toggle="dropdown" id="userDropdownMenu" title="{FirstName} {LastName}">
              <img alt="Image placeholder" src="{Image}" style="width: 46px; margin-top: -15px;">
          </a>
          <ul class="dropdown-menu" aria-labelledby="userDropdownMenu" style="margin-top: 15px !important;">
              <li>
                  <a class="dropdown-item" href="#" id="loginUserProfileLink">
                    User Profile
                  </a>
              </li>
              <li id="groupLeaderMenuItem" style="display:none;">
                    <a class="dropdown-item" href="#" id="groupLeaderLink">
                        Group Leader
                    </a>
              </li>
              <li id="devoWriterMenuItem" style="display:none;">
                    <a class="dropdown-item" href="#" id="devoWriterLink">
                        Devo Writer
                    </a>
              </li>
              <li>
                  <a class="dropdown-item" href="#" id="loginUserLogutLink">
                    Logout
                  </a>
              </li>
          </ul>
        </div>
        `;

        var parentElement = element.parentNode;

        parentElement.innerHTML =
            userMenuTemplate
                .replace("{Image}", authObj.ThumbnailImage)
                .replace("{FirstName}", authObj.Nickname)
                .replace("{LastName}", authObj.Last_Name);

        // Update Links
        var loginUserProfileLink = document.getElementById("loginUserProfileLink");
        loginUserProfileLink.addEventListener('click', function (event) {
            
            event.preventDefault(); 
            const profileModal = document.getElementById('userProfileModal');
            let modal = bootstrap.Modal.getOrCreateInstance(profileModal)
            modal.show();
        });

        var loginUserLogutLink = document.getElementById("loginUserLogutLink");

        loginUserLogutLink.addEventListener('click', function (event) {
            
            event.preventDefault(); 
            // Process Logout
            self.LogOut();
        });

        if (authObj.isGroupLeader || authObj.isMinistryLeader) {
            const groupLeaderLink = document.getElementById("groupLeaderLink");

            let self = this;

            groupLeaderLink.addEventListener('click', function (event) {
                event.preventDefault();
                // Load Devo Writer JS
                var scriptUrl = "/assets/js/dist/components/groupleaderviewcomponent.bundle.js";

                self.loadScript(scriptUrl, function () {
                    console.log("GroupLeader script has been loaded!");
                });
            });

            // Changed this to Visible
            const groupLeaderMenuItem = document.getElementById("groupLeaderMenuItem");
            groupLeaderMenuItem.style.display = 'block';
        }

        if (authObj.isDevoWriter) {
            // Show Devo Writer Link in User Menu
            const devoWriterLink = document.getElementById("devoWriterLink");

            let self = this;

            devoWriterLink.addEventListener('click', function (event) {
                event.preventDefault();
                // Load Devo Writer JS
                var scriptUrl = "/assets/js/dist/components/devowriterviewcomponent.bundle.js";

                self.loadScript(scriptUrl, function () {
                    console.log("DevoWriter script has been loaded!");
                });

            });

            const devoWriterMenuItem = document.getElementById("devoWriterMenuItem");
            devoWriterMenuItem.style.display = 'block';
        }
    }

    // Load Script Into HEAD
    static loadScript(url, callback) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.onload = callback;
        // Optional: callback to be executed once the script is loaded
        // Optional: make use of IFFE to immediately invoke

        document.head.appendChild(script);
    }

}